import React from "react";
// import { graphql } from "gatsby"

import Layout from "@components/Layout";
import SEO from "../components/seo";
import TextSection from "../components/TextSection";
import ImageWithHeading2 from "../components/ImageWithHeading2";

import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import client from "../components/appolloClient";
import useRandomPreloader from "../components/Preloaders/useRandomPreloader"

const aboutQuery = gql`
  query MyQuery {
    entries(slug: "about-us") {
      ... on aboutUs_aboutUs_Entry {
        id
        fullHeightText
        title

        heroImage {
          ... on heroImage_heroImage_BlockType {
            id
            heading
            image {
              ... on uploads_Asset {
                id
                uri
                url
                title
                hasFocalPoint
              }
            }
            mobileImage {
              ... on uploads_Asset {
                id
                uri
                url
                title
              }
            }
          }
        }
        fullHeightImages {
          ... on fullHeightImages_image_BlockType {
            id
            heading
            image {
              url
              title
            }
            mobileImage {
              url
            }
          }
        }
        callToActions {
          ... on callToActions_callToAction_BlockType {
            id
            titleField
            linkField {
              slug
            }
            image {
              ... on uploads_Asset {
                id
                url
                title
                hasFocalPoint
              }
            }
          }
        }
      }
    }

    seomatic {
      metaTitleContainer
      metaTagContainer
      metaLinkContainer
      metaScriptContainer
      metaJsonLdContainer
      metaSiteVarsContainer
      frontendTemplateContainer
    }
  }
`;

const AboutUsPage = () => {

  const { loading, error, data } = useQuery(aboutQuery, { client });

  if (loading) {
    return <p>.</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  console.log("new data" + JSON.stringify(data));

  const firstMainHeroImage = data?.entries[0]?.heroImage[0]?.image[0]?.url;
  const firstMobileHeroImage =
    data?.entries[0]?.heroImage[0]?.mobileImage[0]?.url;
  const seomatic = data?.seomatic[0];
  const fullHeightText = data?.entries[0]?.fullHeightText;
  const fullHeightImages = data?.entries[0]?.fullHeightImages;

  const heroheading = data?.entries[0]?.heroImage[0]?.heading;
  const callToAction = data?.entries[0]?.callToActions;
  console.log("fullheightimage", callToAction);


  const headerComponent = (
    <ImageWithHeading2
      className="h-screen text-centered"
      tag="h1"
      position="bottom"
      sources={firstMainHeroImage}
      mobileSources={firstMobileHeroImage}
      heading={heroheading}
      alt="ProtoProto Hero Image"
      loading={"eager"}
    />
  );

  return (
    <Layout header={headerComponent} showNav={false} seomatic={seomatic}>
      <TextSection full text={fullHeightText} />
      {/* Full Height Images */}
      {fullHeightImages?.map((imageSources, i) => {
        
        const image = imageSources.image[0].url; 
        const mobileImage = imageSources.mobileImage[0].url; 

        return (
          <ImageWithHeading2
            key={i}
            className="h-content"
            sources={image}
            mobileSources={mobileImage}
            position="center"
            heading={imageSources.heading}
            tag="h2"
          />
        );
      })}
      {/* Call to action */}
      <div className="container about-container-home mx-auto overflow-hidden p-0">
        <div className="flex flex-wrap">
          {callToAction.map((callToActionItem, i) => {
            const imageSrc = callToActionItem.image[0]?.url;
            const slug = callToActionItem.linkField[0]?.slug;
            const title = callToActionItem.titleField;

            return (
              <div className="md:w-1/2 w-full">
              <a
                key={i}
               href={`/${slug}`}
                className="cta-panel 2xl:my-32 2xl:mx-16 xl:my-20 xl:mx-10 lg:my-16 lg:mx-8 relative flex justify-center overflow-hidden"
              >
                <img
                  src={imageSrc}
                  alt={title}
                  className="cta-panel__image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit:"cover"
                  }}
                />
                <h2 className="text-white uppercase xl:text-7xl lg:text-5xl text-4xl underline absolute inset-0 flex justify-center items-center">
                  {title}
                </h2>
              </a>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default AboutUsPage;
