import React from "react"

const ImageWithHeading2 = ({
  heading,
  className,
  text,
  alt = "",
  tag = "div",
  position = "top",
  loading = "lazy",
  onLoad = () => {},
  sources,
  mobileSources,
}) => {
  const Tag = tag;

  return (
    <div className={`relative h-screen text-centered`}>
      <div className={`w-full h-full absolute flex justify-center overflow-hidden z-10`}>
        <div className={`Plx Plx--active Plx--in Plx--in-0`} style={{ willChange: "transform", transform: "translateY(244px)" }}>
          <div>
            <h1 className={`text-white text-center md:text-5xl lg:text-8.3xl text-5xl px-8 leading-tight parallax-heading mt-5-pers`} dangerouslySetInnerHTML={{ __html: heading }}></h1>
          </div>
        </div>
      </div>
      <div className={`${mobileSources ? "lg:block hidden" : "block"} h-full`}>
        <img
          src={sources}
          alt={alt}
          loading={loading}
          onLoad={onLoad}
          style={{ width: "100%", height: "100%", objectFit:"cover" }}
        />
      </div>
      {mobileSources && (
        <div className="lg:hidden block h-full">
          <img
            src={mobileSources}
            alt={alt}
            loading={loading}
            onLoad={onLoad}
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageWithHeading2;
